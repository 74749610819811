import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'lux/components/translation';
import { HrBrand } from 'lux/models/employer/employerInfo';

type Props = {
    hrBrand: HrBrand;
};

const TrlKeys = {
    winners: 'employer.page.hrbrand.winner',
    nominees: 'employer.page.hrbrand.nominee',
};

const HrBrandContent: TranslatedComponent<Props> = ({ hrBrand, trls }) => {
    if (!hrBrand) {
        return null;
    }
    return (
        <>
            {Object.keys(hrBrand).map((hrBrandType) => {
                const year = hrBrand[hrBrandType]?.['@year'];
                return <p key={hrBrandType}>{`${trls[TrlKeys[hrBrandType]]}${year ? ` ${year}` : ''}`}</p>;
            })}
        </>
    );
};
export default translation(HrBrandContent);
