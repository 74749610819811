import { Title, Cell, Avatar, CellText, Card, VSpacingContainer } from '@hh.ru/magritte-ui';
import { PhoneOutlinedSize24, EnvelopeOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { Link as ReduxLink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { VacancyResponsePopupResponse } from 'lux/components/VacancyResponseLink';
import translation from 'lux/components/translation';
import { ContactInfo } from 'lux/models/vacancyView.types';

import ContactChat from 'lux/components/VacancyView/ContactChat';

interface Props {
    vacancyId: number;
    contactInfo?: ContactInfo | null;
    isCallTrackingEnabled: boolean;
    onPhoneClick?: () => void;
    onEmailClick?: () => void;
    chatButtonInfo?: VacancyResponsePopupResponse | null;
    employerId?: number;
    isChatEnabled?: boolean;
}

const TrlKeys = {
    textC: 'vacancy.contacts.callTracking.disclaimer.text.c',
    phone: 'vacancy.contacts.phone',
    email: 'vacancy.contacts.email',
};

const VacancyContactsMagritte: TranslatedComponent<Props> = ({
    vacancyId,
    contactInfo,
    onPhoneClick,
    onEmailClick,
    isCallTrackingEnabled,
    isChatEnabled,
    chatButtonInfo,
    employerId,
    trls,
}) => {
    const { fio, email } = contactInfo || {};
    const phones = contactInfo?.phones?.phones;

    return (
        <VSpacingContainer default={24}>
            {fio && (
                <Title size="small" Element="h4">
                    {fio}
                </Title>
            )}
            {chatButtonInfo && isChatEnabled && employerId && (
                <ContactChat
                    chatButtonInfo={chatButtonInfo}
                    vacancyId={vacancyId}
                    employerId={employerId}
                    isMagritteExp
                />
            )}
            {!!phones?.length && isCallTrackingEnabled && (
                <Card borderRadius={12} padding={16} style="positive">
                    {trls[TrlKeys.textC]}
                </Card>
            )}
            {phones?.map((phone) => {
                const { country, city, number, comment } = phone;
                const formattedPhone = `+${country}\u00a0${city}\u00a0${number}`;
                return (
                    <Cell
                        key={number}
                        avatar={
                            <Avatar
                                aria-label={trls[TrlKeys.phone]}
                                mode="icon"
                                size={48}
                                style="secondary"
                                icon={<PhoneOutlinedSize24 initial="primary" />}
                            />
                        }
                    >
                        <CellText type="subtitle">{trls[TrlKeys.phone]}</CellText>
                        <ReduxLink to={`tel:+${country}${city}${number}`} onClick={onPhoneClick}>
                            <CellText type="title">{formattedPhone}</CellText>
                        </ReduxLink>
                        <CellText type="subtitle">{comment}</CellText>
                    </Cell>
                );
            })}
            {email && (
                <Cell
                    avatar={
                        <Avatar
                            aria-label={trls[TrlKeys.email]}
                            mode="icon"
                            size={48}
                            style="secondary"
                            icon={<EnvelopeOutlinedSize24 initial="primary" />}
                        />
                    }
                >
                    <CellText type="title">{trls[TrlKeys.email]}</CellText>
                    <ReduxLink to={`mailto:${email}`} onClick={onEmailClick}>
                        <CellText type="subtitle">{email}</CellText>
                    </ReduxLink>
                </Cell>
            )}
        </VSpacingContainer>
    );
};

export default translation(VacancyContactsMagritte);
