import { FC, useRef } from 'react';

import { TooltipHover } from '@hh.ru/magritte-ui';
import {
    EmployersRatingOutlinedCircledSize16,
    EmployersRatingOutlinedSize16,
    EmployersRatingOutlinedSize24,
} from '@hh.ru/magritte-ui/product';
import { Link } from '@hh.ru/redux-spa-middleware';
import HoverTip, { TipPlacement, TipLayer } from 'bloko/blocks/drop/Tip/HoverTip';

import BadgeContainer from 'lux/components/Employer/BadgeContainer';
import { Badge } from 'lux/models/vacancyView.types';

const IconSizeMap = {
    small: EmployersRatingOutlinedSize16,
    medium: EmployersRatingOutlinedSize24,
} as const;

export type RatingIconSize = keyof typeof IconSizeMap;

type Props = {
    employerHHRating: Badge;
    size?: RatingIconSize;
    dataQaPrefix: string;
    isRedesignedSerp?: boolean;
    isMagritte?: boolean;
};

const EmployerHHRatingIcon: FC<Props> = ({
    employerHHRating,
    size = 'small',
    dataQaPrefix,
    isRedesignedSerp,
    isMagritte,
}) => {
    const activatorRef = useRef<HTMLSpanElement>(null);

    if (isMagritte) {
        return (
            <Link
                to={employerHHRating.url}
                target="_blank"
                rel="nofollow noindex"
                data-qa={`${dataQaPrefix}${employerHHRating.type}` || ''}
            >
                <span ref={activatorRef}>
                    <EmployersRatingOutlinedCircledSize16 initial="warning-secondary" />
                </span>
                <TooltipHover placement="top-center" activatorRef={activatorRef}>
                    {employerHHRating.description}
                </TooltipHover>
            </Link>
        );
    }

    const IconComponent = IconSizeMap[size];

    return (
        <Link
            to={employerHHRating.url}
            target="_blank"
            rel="nofollow noindex"
            data-qa={`${dataQaPrefix}${employerHHRating.type}` || ''}
        >
            <HoverTip
                render={() => employerHHRating.description}
                placement={TipPlacement.Top}
                layer={TipLayer.Overlay}
                host={!process.env.LUX_SERVER ? document.body : null}
            >
                {isRedesignedSerp ? (
                    <span className={'vacancy-serp-bage-hr-rating'} />
                ) : (
                    <span>
                        <BadgeContainer size={size} icon={<IconComponent scale={size} initial="accent" />} />
                    </span>
                )}
            </HoverTip>
        </Link>
    );
};

export default EmployerHHRatingIcon;
