import { FC } from 'react';

import { VSpacing } from '@hh.ru/magritte-ui';
import HSpacingBloko from 'bloko/blocks/hSpacing';
import TextBloko from 'bloko/blocks/text';
import VSpacingBloko from 'bloko/blocks/vSpacing';

import { useBrandedSnippetContext } from 'lux/components/BrandedSnippet/context/BrandedSnippetContext';
import ItAccreditation from 'lux/components/Employer/ItAccreditation';
import TrustedEmployerCheck, { TrustedEmployerCheckSize } from 'lux/components/Employer/TrustedEmployerCheck';
import { Address as AddressType } from 'lux/components/Employer/VacanciesGroupsByRegion/VacancyGroupsTypes';
import EmployerBadgesIconWrapper from 'lux/components/EmployerBadges/EmployerBadgesIconWrapper';
import PrepareEmployerHHRating from 'lux/components/EmployerHHRatingIcon/PrepareEmployerHHRating';
import InsiderInterviewLink from 'lux/components/InsiderInterviewLink';
import Address from 'lux/components/VacancySearchItem/Address';
import CompanyName from 'lux/components/VacancySearchItem/CompanyName';
import Divider from 'lux/components/VacancySearchItem/Divider';
import EmployerOnline from 'lux/components/VacancySearchItem/EmployerOnline';
import HRBrand from 'lux/components/VacancySearchItem/HRBrand';
import VacancyLogo from 'lux/components/VacancySearchItem/Logo';
import { useCardContainerClassNames } from 'lux/components/VacancySearchItem/hooks/useCardContainerClassNames';
import translation from 'lux/components/translation';
import useGetCompanyHHRatingBadge from 'lux/hooks/companies/useGetCompanyHHRatingBadge';
import useMagritte from 'lux/hooks/useMagritte';
import { VacancyCompany as VacancyCompanyType } from 'lux/models/vacancySearch/vacancySearchItem.types';
import { VacancyViewInsider } from 'lux/models/vacancyView.types';

import styles from './styles.less';

type CompanyProps = {
    company: VacancyCompanyType;
    advClickUrl?: string;
    hrBrand?: {
        type: string;
        '@url': string;
        '@year': number;
    };
    insider?: VacancyViewInsider;
    area?: {
        '@id': number;
        name: string;
    };
    address?: AddressType;
    isEmployerOnline: boolean;
    shouldShowLogo: boolean;
};

const Company: FC<CompanyProps> = ({
    company,
    advClickUrl,
    hrBrand,
    insider,
    area,
    address,
    isEmployerOnline,
    shouldShowLogo,
}) => {
    const isMagritte = useMagritte();
    const { wideCardContainerClassName, narrowCardContainerClassName, separateLineOnNarrowCardClassName } =
        useCardContainerClassNames();
    const employerHHRatingBadge = useGetCompanyHHRatingBadge(company?.badges);

    const hasBrandedSnippet = !!useBrandedSnippetContext();

    if (!company?.visibleName) {
        return null;
    }

    const hasAtLeastOneBadge = Boolean(
        company['@trusted'] || company.accreditedITEmployer || hrBrand || employerHHRatingBadge
    );
    const hasInsiderLink = insider && (insider['@isVisible'] || insider.isVisible) && insider.id;

    if (isMagritte) {
        const badgesElement = (
            <>
                {company['@trusted'] && <TrustedEmployerCheck isMagritte size={TrustedEmployerCheckSize.Small} />}
                {company.accreditedITEmployer && <ItAccreditation isMagritte />}
                {hrBrand && <HRBrand hrBrand={hrBrand} isMagritte />}
                {employerHHRatingBadge && (
                    <PrepareEmployerHHRating
                        badge={employerHHRatingBadge}
                        dataQaPrefix="vacancy-serp__vacancy_"
                        isMagritte
                    />
                )}
                {isEmployerOnline && <EmployerOnline isEmployerOnline={isEmployerOnline} isMagritte />}
            </>
        );

        return (
            <div className={styles.infoSection}>
                <div className={wideCardContainerClassName}>
                    <VSpacing default={16} />

                    {!hasBrandedSnippet && (
                        <div className={styles.vacancyLogoContainer}>
                            <VacancyLogo company={company} shouldShow={shouldShowLogo} />
                        </div>
                    )}

                    <div className={styles.companyNameBadgesContainer}>
                        <CompanyName company={company} advClickUrl={advClickUrl} />

                        {hasAtLeastOneBadge && badgesElement}
                    </div>

                    <Address area={area} address={address} />

                    {hasInsiderLink && (
                        <>
                            <VSpacing default={8} />

                            <InsiderInterviewLink insider={insider ?? null} companyId={company.id} isShortText />
                        </>
                    )}
                </div>

                <div className={narrowCardContainerClassName}>
                    <VSpacing default={24} />
                    {hasAtLeastOneBadge && (
                        <>
                            <div className={styles.companyNameBadgesContainer}>{badgesElement}</div>
                            <VSpacing default={4} />
                        </>
                    )}
                    {!hasBrandedSnippet && (
                        <div className={styles.vacancyLogoContainer}>
                            <VacancyLogo company={company} shouldShow={shouldShowLogo} />
                        </div>
                    )}
                    <CompanyName company={company} advClickUrl={advClickUrl} />

                    <Address area={area} address={address} />

                    {hasInsiderLink && (
                        <>
                            <VSpacing default={12} />

                            <InsiderInterviewLink insider={insider ?? null} companyId={company.id} isShortText />
                        </>
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className={styles.infoSection}>
            <VSpacingBloko base={4} />

            {!hasBrandedSnippet && (
                <div className={styles.vacancyLogoContainer}>
                    <VacancyLogo company={company} shouldShow={shouldShowLogo} />
                </div>
            )}

            <span className={separateLineOnNarrowCardClassName}>
                <TextBloko Element="span">
                    <CompanyName company={company} advClickUrl={advClickUrl} />
                </TextBloko>

                {hasAtLeastOneBadge && (
                    <>
                        <HSpacingBloko base={2} />
                        <EmployerBadgesIconWrapper>
                            {company['@trusted'] && <TrustedEmployerCheck size={TrustedEmployerCheckSize.Small} />}
                            {company.accreditedITEmployer && <ItAccreditation />}
                            {hrBrand && <HRBrand hrBrand={hrBrand} isRedesignedSerp />}
                            {employerHHRatingBadge && (
                                <PrepareEmployerHHRating
                                    badge={employerHHRatingBadge}
                                    isRedesignedSerp
                                    dataQaPrefix="vacancy-serp__vacancy_"
                                />
                            )}
                        </EmployerBadgesIconWrapper>
                    </>
                )}
            </span>

            <span className={wideCardContainerClassName}>
                {isEmployerOnline && (
                    <>
                        <Divider />
                        <EmployerOnline isEmployerOnline={isEmployerOnline} />
                    </>
                )}

                {hasInsiderLink && (
                    <>
                        <Divider />

                        <InsiderInterviewLink insider={insider ?? null} companyId={company.id} isShortText />
                    </>
                )}
            </span>

            <Address area={area} address={address} />

            <div className={narrowCardContainerClassName}>
                {isEmployerOnline && (
                    <>
                        <VSpacingBloko base={2} />
                        <EmployerOnline isEmployerOnline={isEmployerOnline} />
                    </>
                )}

                {hasInsiderLink && (
                    <>
                        <VSpacingBloko base={2} />
                        <div>
                            <InsiderInterviewLink insider={insider ?? null} companyId={company.id} isShortText />
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default translation(Company);
