export enum VacancyProperty {
    // defining hh
    HhFree = 'HH_FREE',
    HhStandard = 'HH_STANDARD',
    HhStandardPlus = 'HH_STANDARD_PLUS',
    HhPremium = 'HH_PREMIUM',
    // defining zp
    ZpPromo = 'ZP_PROMO', // standard
    ZpBusiness = 'ZP_BUSINESS', // standard plus
    ZpPremium = 'ZP_PREMIUM',
    ZpDemo = 'ZP_DEMO', // free
    // classifying hh
    HhAnonymous = 'HH_ANONYMOUS',
    HhAdvertising = 'HH_ADVERTISING',
    HhPromo = 'HH_PROMO',
    // classifying zp
    ZpAnonymous = 'ZP_ANONYMOUS',
    ZpCrossposting = 'ZP_CROSSPOSTING',
    // additional features
    HhSearchResultsLogo = 'HH_SEARCH_RESULTS_LOGO',
    HhSearchResultsLabel = 'HH_SEARCH_RESULTS_LABEL',
    ZpManualRenewal = 'ZP_MANUAL_RENEWAL',
    ZpSearchResultsLogo = 'ZP_SEARCH_RESULTS_LOGO',
    ZpSearchResultsLabel = 'ZP_SEARCH_RESULTS_LABEL',
}

interface Shortcut {
    isFree: boolean;
    isStandard: boolean;
    isStandardPlus: boolean;
    isPremium: boolean;
    isAdvertising: boolean;
    isAnonymous: boolean;
    isPromo: boolean;
    isPayForPerformance: boolean;
    hasOptionPremium: boolean;
    typeTrl: string;
}

type FormattedProperties = {
    [x in VacancyProperty]?: {
        parameters: Record<string, unknown>;
    };
};

type Properties = Array<{
    id: number;
    propertyType: string;
    startTimeIso: string;
    endTimeIso: string;
    parameters: Array<{
        key: string;
        value: string;
    }>;
}>;

type StrangeNestedProperties = {
    property: Array<{
        id: number;
        propertyType: string;
        startTimeIso: string;
        endTimeIso: string;
        parameters: {
            parameter: Array<{
                key: string;
                value: string;
            }>;
        };
    }>;
};

export interface VacancyProperties {
    properties: Properties | StrangeNestedProperties;
    id?: string;
    converted?: {
        shortcut: Shortcut;
        formattedProperties: FormattedProperties;
    };
    calculatedStates?: CalculatedProperties;
}

// id пакета пропертей, полученный конкатенацией названий defining и classifying пропертей в пакете, например, HH-STANDARD_HH_ANONYMOUS
export type PublicationVacancyPropertiesId = string;

export interface PublicationVacancyProperties {
    id: PublicationVacancyPropertiesId;
    properties: { propertyType: VacancyProperty; parameters: Record<string, unknown>[] }[];
    converted: {
        shortcut: Shortcut;
        formattedProperties: FormattedProperties;
    };
    calculatedStates: CalculatedProperties;
}

export type PropertyPlatform = 'HH' | 'ZP';

export type CalculatedProperties = Record<PropertyPlatform, PlatformProperties>;

export interface PlatformProperties {
    advertising: boolean;
    anonymous: boolean;
    crosspostedTo?: PropertyPlatform[];
    crosspostedFrom?: PropertyPlatform;
    filteredPropertyNames: VacancyProperty[];
    free: boolean;
    optionPremium: boolean;
    payForPerformance: boolean;
    premium: boolean;
    searchPositionFree: boolean;
    searchPositionNormal: boolean;
    searchPositionPremium: boolean;
    standard: boolean;
    standardPlus: boolean;
    translationKeys: string[];
    translation?: string;
}
